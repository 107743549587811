import React from 'react'
import'./portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'



const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Site marcketplace e-commerce. Projet réaliser en stage',
    github: 'https://github.com',
    demo: 'https://sunushop.com'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Site d\'entreprise événnementiel. Projet réaliser en Freelance',
    github: 'https://github.com',
    demo: 'https://jinaneevents.com'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Site de vente en ligne. Projet réaliser en Freelance',
    github: 'https://github.com',
    demo: 'https://alhidiab.com'
  },
  
  {
    id: 4,
    image: IMG4,
    title: 'Mon portfolio react',
    github: 'https://github.com',
    demo: 'https://toure-devfolio.epizy.com/'
  },
 
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>Mes Recentes Projets</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
      {
        data.map(({id, image, title, github, demo}) => {
          return(
            <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={image} alt="Portfolio1" />
          </div>
          <h3>{title}</h3>
          <div className='portfolio__item-cta'>
          <a href={github} className='btn' target='_blank' rel="noopener noreferrer">Github</a>
          <a href={demo} className='btn btn-primary' target='_blank' rel="noopener noreferrer">Demo en live </a>
          </div>
        </article>
          )
        })
      }
      </div>
    </section>
  )
}

export default Portfolio