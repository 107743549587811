import React from 'react'
import './services.css'
import {BsCheck} from 'react-icons/bs'
const Services = () => {
  return (
    <section id='services'>
      <h5>Mes Offres</h5>
      <h2>Services</h2>

      <div className="container services__container">
         <article className="service">
          <div className="service__head">
            <h3>Web conception</h3>
          </div>
{/* 
          <ul className="service__list">
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
           
          </ul>
          */}
        </article>
        {/*Fin UI/UX design */}
        <article className="service">
          <div className="service__head">
            <h3>Developpement web</h3>
          </div>
{/*
          <ul className="service__list">
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adt.</p>
            </li>
           
          </ul>
          */}
        </article>

        {/*Fin UI/UX design */}
        <article className="service">
          <div className="service__head">
            <h3>SEO Optimisation</h3>
          </div>
{/*
          <ul className="service__list">
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adi.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adi.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adi.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adi.</p>
            </li>
            <li>
            <BsCheck className='service__list-icon'/>
            <p>Lorem ipsum dolor sit amet, consectetur adi.</p>
            </li>
           
          </ul>
          */}
        </article>
        {/*Fin Mes Creations */}
      </div>
    </section>
  )
}

export default Services