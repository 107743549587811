import React from 'react'
import './header.css'
import CTA from './CTA'
import MOI from '../../assets/moi.png'
import HeaderSocials from './HeaderSocials'


const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h4>Bienvenue dans mon espace professionnel</h4>
        <h5>je suis</h5>
        <h1>Abdou Rahmane Touré</h1>
        <h5 className="text-light">Développeur Full Stack</h5>
        <CTA />
        <HeaderSocials />

        <div className="MOI">
        <img src={MOI} alt="Abdou Rahmane Toure" />
        </div>

        <a href="#contact" className='scroll__down'>C&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt; </a>

      </div>
    </header>
  )
}

export default Header