import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/toure.jpg'

// Swiper import
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'


const data = [
  {
    avatar: AVTR1,
    nom: 'TOURE',
    review: 'Dignissimos deleniti iure incidunt officia totam hic velit necessitatibus aperiam voluptatem dicta Voluptatum veniam quas officia beatae doloremque placeat cumque non facere nemo modi dolor repudiandae doloribus repellendus at nisi Ex atque',
  },
  {
    avatar: AVTR1,
    nom: 'TOURE',
    review: 'Voluptatum veniam quas officia beatae doloremque placeat cumque non facere nemo modi dolor repudiandae doloribus repellendus at nisi Ex atque',
  },
  {
    avatar: AVTR1,
    nom: 'TOURE',
    review: 'Dignissimos deleniti iure incidunt officia totam hic velit necessitatibus aperiam voluptatem dicta Voluptatum veniam quas officia beatae doloremque placeat cumque non facere nemo modi dolor repudiandae doloribus repellendus at nisi Ex atque',
  },
  {
    avatar: AVTR1,
    nom: 'TOURE',
    review: 'hic velit necessitatibus aperiam voluptatem dicta Voluptatum veniam quas officia beatae doloremque placeat cumque non facere nemo modi dolor repudiandae doloribus repellendus at nisi Ex atque',
  },
]
const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Avis des clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      
      
      
       // install Swiper modules
       modules={[Pagination]}
       spaceBetween={40}
       slidesPerView={1}
       pagination={{ clickable: true }}
       >
        {
          data.map(({avatar, nom, review}, index) =>{
            return(
              <SwiperSlide key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} alt="mes client"/>
                </div>
                <h5 className="client__name">{nom}</h5>
                <small className="client__review">
                  {review}
                </small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials