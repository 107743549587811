import React from 'react'
import './about.css'
import MOI from '../../assets/toure.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const about = () => {
  return (
    <section id='about'>
      <h5>Qui suis-je ?</h5>
      <h2>A Propos De Moi</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me__image">
            <img src={MOI} alt="Abdourahmane toure" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experiences</h5>
              <small>2+ ans d'experience</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>3+ clients</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projets</h5>
              <small>3+ Réalisations</small>
            </article>
          </div>
          <p>
          Je suis a la fois Développeur web mobile certifier avec plusieur projets web a mon actif et actuellement en fin de formation en licence 3 Génies informatiques et programmation. J’ai toujours aimé relever les défis raison pour laquelle durant les vacances de ces trois dernières années de mon cursus j’ai eu à travailler sur des projets en solo comme en équipe ce qui m’a permis d’acquérir des bases solides en programmation et dans le relationnel. Les projets sur lesquelles j’ai eu à travailler jusque-là ont toute leur particularité et je suis actuellement ouvert à toutes les opportunités.
          </p>
          <a href="#contact" className='btn btn-primary'>Me contacter</a>
        </div>
      </div>
    </section>
  )
}

export default about