import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Mes competences</h5>
      <h2>Mes experiences</h2>
      <div className="container experience__container">

        <div className="experience__frontend">

          <h3>Développement Frontend</h3>
          <div className="experience__content">
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>HTML</h4>
              <small className='text-light'>Avancé</small>
             </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>CSS</h4>
              <small className='text-light'>Avancé</small>
             </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div><h4>React</h4>
              <small className='text-light'>Intermédiaire</small></div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Symphony</h4>
              <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>JavaScript</h4>
              <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>BootsTrap</h4>
              <small className='text-light'>Avancé</small>
              </div>
            </article>
            
          </div>

        </div>

        <div className="experience__backend">

        <h3>Développement Backend</h3>
          <div className="experience__content">
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>PHP</h4>
              <small className='text-light'>Avancé</small>
             </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>MySQL</h4>
              <small className='text-light'>Avancé</small>
             </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Node JS</h4>
              <small className='text-light'>Intérmediaire</small>
              </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4>Mongo DB</h4>
              <small className='text-light'>Intérmediaire</small>
              </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>Python</h4>
              <small className='text-light'>Débutant</small>
             </div>
            </article>
            <article className='experience__detail'>
              <BsPatchCheckFill className='experience__details-icon'/>
             <div>
             <h4>java et xml</h4>
              <small className='text-light'>Sous Android studio</small>
             </div>
            </article>
            
          </div>
          
        </div>

      </div>

      







      <div className="container experience__container2 ">
        
<div className="experience__backend">

<h3>CMS</h3>
  <div className="experience__content">
    <article className='experience__detail'>
      <BsPatchCheckFill className='experience__details-icon'/>
     <div>
     <h4>WordPress</h4>
      <small className='text-light'>Avancé</small>
     </div>
    </article>
    <article className='experience__detail'>
      <BsPatchCheckFill className='experience__details-icon'/>
     <div>
     <h4>drupal</h4>
      <small className='text-light'>Avancé</small>
     </div>
    </article>
      
  </div>
  
     <div className='comment'>
      <p>Avec de solide experience en entreprise.</p>
      <a href="#portfolio" className='btn'>Voir portfolio</a>
     </div>
</div>

</div>
    </section>
  )
}

export default Experience