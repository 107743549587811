
import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w1nnwiq', 'template_8z1m1hq', form.current, 'HtHX50UQkyNPji2e_')
      e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Contact</h5>
      <h2>Me joindre</h2>
      <div className="container contact__container">
        <div className="contact__options">
        <article className="contact__option">
          <MdOutlineEmail className='contact__option-icon'/>
          <h4>Mail</h4>
          <h6>abdourahmane.toure674@gmail.com</h6>
          <a href="mailto:abdourahmane.toure674@gmail.com" target="_blank" rel='noopener noreferrer'>Envoyer moi un mail</a>
          </article>
          <article className="contact__option">
          <RiMessengerLine className='contact__option-icon'/>
          <h4>Messenger</h4>
          <h5>abdourahmane</h5>
          <a href="https://m.me/abdourahmane.toure.75491856" target="_blank" rel='noopener noreferrer'>Envoyer moi un mail</a>
          </article>
          <article className="contact__option">
          <BsWhatsapp className='contact__option-icon'/>
          <h4>Whatsapp</h4>
          <h5>+221777618183</h5>
          <a href="https://api.whatsapp.com/send?phone=221777618183" target="_blank" rel='noopener noreferrer'>Envoyer moi un mail</a>
          </article>
        </div>
        {/*end of contact contact option*/}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Nom Complet' required />
          <input type="email" name='email' placeholder='Votre e-mail' required />
          <textarea name="message" rows="7" placeholder='votre message' required ></textarea>
          <button type='submit' className='btn btn-primary'>Envoyer</button>
        </form>
      </div>
    </section>
  )
}

export default contact